<style lang="scss">
  .map-container{
    height: 350px;
  }
</style>
<template>
  <div>
    <b-card>
      <b-row v-if="!loading">
        <b-col md="4">
          <b-form-group label="Seleccione parámetro de búsqueda">
            <v-select :options="fieldOptions" v-model="fieldType" label="text" :filterable="true" :clearable="true" placeholder="De clic para seleccionar parámetro de búsqueda" :reduce="op => op.value">
              <template #no-options>
                  No hay medidores para instalar
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Seleccione Medidor GWH00-79 a installar">
            <v-select :options="metersNotInstalled" v-model="meterSearchValue" :label="fieldType" :filterable="true" :clearable="true" placeholder="De clic para seleccionar uno" :reduce="op => op[fieldType]" :disabled="!fieldType">
              <template #no-options>
                  No hay medidores para instalar
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="." style="color:#FFFFFF">
            <b-button variant="primarySpore" @click="getCabinets" v-b-tooltip.hover.top title="Comenzar instalación de Medidor GWH00-79" :disabled="!meterSearchValue">
              <feather-icon icon="LinkIcon"/>
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="loading">
        <b-col md="12" class="text-center">
          <b-spinner label="Spinning" variant="primarySpore" />
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="statusForm">
      <b-row>
        <b-col md="5">
          <b-row>
            <b-col md="12">
              <b-form-group label="Módulo (serie spore)">
                <b-form-input id="numberSpore" v-model="numberSpore" @update="textTransform($event, 'numberSpore')"/>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Tipo de Medidor">
                <v-select :options="opcMeterType" v-model="meterType"/>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="División">
                <v-select :options="opcDivision" v-model="division" :disabled="true"/>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Zona">
                <v-select :options="opcZone" v-model="zone" :disabled="!(division.length)">
                  <template #no-options>
                      No hay zonas para seleccionar. Vaya a la sección de zonas y cree una para continuar.
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Agencias">
                <v-select :options="opcAgencies" v-model="agencies" :disabled="!(zone && zone.value)" label="name">
                  <template #no-options>
                      No hay agencias para seleccionar. Vaya a la sección de agencias y cree una para continuar.
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="7">
          <b-row>
            <b-col md="12">
              <b-row>
                <b-col md="6">
                  <b-form-group label="Número de Medidor">
                    <b-form-input id="meterNumber" v-model="meterNumber"  @update="textTransform($event, 'meterNumber')"/>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Serie de Tarjeta">
                    <b-form-input id="serieCard" v-model="serieCard" disabled/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Calle">
                    <b-form-input id="street" v-model="street"/>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Número Exterior">
                    <b-form-input id="numExt" v-model="numExt"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Colonia">
                    <b-form-input id="suburb" v-model="suburb"/>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Código Postal">
                    <b-form-input id="postalCode" v-model="postalCode"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Localidad">
                    <b-form-input id="location" v-model="location"/>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Municipio">
                    <b-form-input id="municipality" v-model="municipality"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Estado">
                    <b-form-input id="state" v-model="state"/>
                  </b-form-group>
                </b-col>
                <b-col md="6" class="mt-1">

                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
            <b-col md="8" style="margin-top: 15px">
              <b-form-group label="Latitud y Longitud (Puede mover el Pin del mapa a la ubicación deseada o escibir la latitud y longitud en la caja de texto)" label-size="sm">
                <b-form-input id="latlong" v-model="latlong" />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group style="color:#FFFFFF" >
                <b-button style="margin-top: 30px" variant="primarySpore" @click="sendLatlong" >
                  Localizar
                </b-button>
              </b-form-group>
            </b-col>
        <b-col md="12" class="mt-2">
              <div class="map-container" style="border: 1px solid #ccc; border-radius: 5px;">
                <l-map :center="center" :zoom="zoom" :options="{attributionControl: false, fullscreenControl: true, zoomControl: false}">
                  <l-control-zoom position="topleft" :options="{ zoomInTitle: 'Acercar', zoomOutTitle: 'Alejar' }"/>
                  <l-control-layers
                    position="topright"
                    :collapsed="true"
                    :sort-layers="true"
                  />
                  <l-control-attribution position="bottomright" prefix="Spore Cloud"/>
                  <l-tile-layer
                    v-for="tileProvider in tileProviders"
                    :key="tileProvider.url"
                    :name="tileProvider.name"
                    :visible="tileProvider.visible"
                    :url="tileProvider.url"
                    layer-type="base"
                  />
                  <l-marker :draggable="true" :lat-lng="marker" @dragend="changeMarker" ref="position_marker">
                    <l-icon :icon-anchor="staticAnchor">
                      <b-img
                        rounded
                        :src="require(`@/assets/images/markers/LPoint.png`)"
                        height="50"
                      />
                    </l-icon>
                  </l-marker>
                </l-map>
              </div>
            </b-col>
        <b-col
          md="12"
          class="mt-3 text-right"
        >
          <b-button
            class=""
            variant="primarySpore"
            @click="putRegister"
          >
            <feather-icon
              icon="ArrowRightCircleIcon"
            />
            siguiente
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BImg, VBTooltip, BSpinner
} from 'bootstrap-vue'
import request from '@/libs/request/index'
import vSelect from 'vue-select'
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LControlAttribution,
  LControlZoom,
  LControlLayers
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import { formatDateLanguages, empty, decimales } from '@/libs/tools/helpers'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen'
import { logout } from '@/libs/session'
import store from '@/store'

const userData = store.state.session.AppActiveUser()

/* global L:true */
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LControlLayers,
    LControlAttribution,
    BImg,
    LControlZoom,
    BSpinner
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      divisionUuid: userData.division.uuid,
      loading: false,
      metersNotInstalled: [],
      fieldOptions: [
        { value: 'serie', text: 'Número de Serie' },
        { value: 'serieCard', text: 'Número de Serie de Tarjeta' },
        { value: 'meterNumber', text: 'Número de Medidor' }
      ],
      tileProviders: [
        {
          name: 'Calles',
          visible: true,
          url: 'https://{s}.tile.openstreetmap.fr/osmfr//{z}/{x}/{y}.png'
        },
        {
          name: 'Gris',
          visible: false,
          url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
        },
        {
          name: 'Satelite',
          visible: false,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png'
        }
      ],
      opcAgencies: [],
      opcDivision: [],
      opcZone: [],
      opcMeterType: [],
      agencies: [],
      division: [],
      zone: null,
      meterType: [],
      response: [],
      numberSpore: '',
      meterNumber: '',
      serieCard: '',
      fieldType: null,
      meterSearchValue: null,
      gabinetsUuid: '',
      latlong: '',
      street: '',
      numExt: '',
      suburb: '',
      postalCode: '',
      location: '',
      municipality: '',
      state: '',
      zoom: 5,
      center: L.latLng(20.6242631, -100.9927305),
      url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
      attribution: '',
      marker: L.latLng(20.6242631, -100.9927305),
      staticAnchor: [12, 27],
      statusForm: false,
      tableColumnsMetering: [
        { key: 'Action', label: 'SiNaMed' },
        { key: 'Number', label: 'Número', sortable: true },
        { key: 'ElectricCurrent', label: 'Lectura', sortable: true },
        { key: 'Position', label: 'Posición', sortable: true },
        { key: 'Status', label: 'Estatus', sortable: true },
        { key: 'Transmited', label: 'Última transmisión', sortable: true },
        { key: 'Register', label: 'Registrado', sortable: true }
      ]
    }
  },
  methods: {
    textTransform (val, name) {
      this[name] = val.toUpperCase()
    },
    decimal (val, n) {
      return decimales(val, n)
    },
    getValue (numero, register, transmited) {
      return `Número de medidor: ${numero} | Fecha de registro: ${register} | Fecha de transmisión: ${transmited}`
    },
    exit (uuid) {
      this.$router.push('/gabinets')
    },
    changeMarker () {
      const location = this.$refs.position_marker.mapObject.getLatLng()
      this.latlong = `${location.lat}, ${location.lng}`
    },
    isEmpty (val) {
      return empty(val)
    },
    sendLatlong () {
      const lat = this.latlong.split(',')[0]
      const long = this.latlong.split(',')[1]
      this.zoom = 15
      this.marker = L.latLng(lat, long)
      this.center = L.latLng(lat, long)
    },
    format (date) {
      return formatDateLanguages(date, 'hour')
    },
    async getCatDivision () {
      this.opcDivision = []
      const params = {
        url: '/divisions/list/all',
        method: 'GET'
      }
      await request(params).then(data => {
        for (let index = 0; index < data.data.data.length; index++) {
          this.opcDivision.push({
            label: data.data.data[index].name,
            title: data.data.data[index].name,
            value: data.data.data[index].number,
            uuid: data.data.data[index].uuid
          })
        }
      })
    },
    async getCatZones () {
      this.opcZone = []
      const params = {
        url: '/zones',
        method: 'GET',
        params: {
          divisionUuid: this.division[0].uuid
        }
      }
      await request(params).then(({ data }) => {
        for (const zona of data) {
          this.opcZone.push({
            label: zona.name,
            title: zona.name,
            value: zona.uuid
          })
        }
      })
      if (this.zone) {
        this.zone = this.opcZone.find(el => el.value === this.zone.value)
      }
    },
    async getCatAgencies () {
      const params = {
        url: '/agencies/list/all',
        method: 'GET',
        params: {
          zoneUuid: this.zone.value
        }
      }
      await request(params).then(({ data }) => {
        this.opcAgencies = data.data
        if (this.agencies) {
          this.agencies = this.opcAgencies.find(el => el.uuid === this.agencies.uuid)
        }
      })
    },
    async getCabinets () {
      if (empty(this.meterSearchValue)) {
        this.$swal({
          title: '¡Atención!',
          text: 'Seleccioner el número de un medidor',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      } else {
        const params = {
          url: '/meters079/byField',
          method: 'GET',
          params: {
            [this.fieldType]: this.meterSearchValue
          }
        }
        request(params).then(({ data }) => {
          const { meter } = data
          this.statusForm = true
          this.meterUuid = meter.uuid
          this.numberSpore = meter.serie
          this.meterNumber = meter.meterNumber
          this.serieCard = meter.serieCard
          this.street = meter.street
          this.numExt = meter.numExt
          this.suburb = meter.suburb
          this.postalCode = meter.postalCode
          this.location = meter.location
          this.municipality = meter.municipality
          this.state = meter.state
          this.division = this.opcDivision.filter(el => { return el.uuid === meter.divisionUuid })
          this.zone = { value: meter.zoneUuid }
          this.agencies = { uuid: meter.agencyUuid }
          this.meterType = this.opcMeterType.filter(el => { return el.value === meter.typeTecnology })[0]
          if (meter.latlong) {
            this.latlong = meter.latlong
            const lat = meter.latlong.split(',')[0]
            const long = meter.latlong.split(',')[1]
            this.zoom = 18
            this.marker = L.latLng(lat, long)
            this.center = L.latLng(lat, long)
          }
        }).catch(e => {
          if (e.response.status === 404) {
            this.statusForm = false
            this.numberSpore = ''
            this.agencies = []
            this.division = []
            this.meterType = []
            this.latlong = ''
            this.$swal({
              title: '¡Atención!',
              text: 'No existe el preregistro de ese medidor',
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          }
        })
      }
    },
    async getMetersNotInstalled () {
      this.loading = true
      const params = {
        url: '/meters079/not-installed',
        method: 'GET',
        params: {
          divisionUuid: this.divisionUuid
        }
      }
      request(params).then(({ data }) => {
        this.metersNotInstalled = data.data
      }).catch(e => {
        if (e?.response?.status === 404) {
          this.$swal({
            title: '¡Atención!',
            text: 'No has registrado medidores para instalar',
            icon: 'warning',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        }
        console.log(e)
      }).finally(() => {
        this.loading = false
      })
    },
    async putRegister () {
      if (empty(this.meterNumber) || empty(this.agencies) || empty(this.division) || empty(this.latlong) || empty(this.zone?.value)) {
        this.$swal({
          title: '¡Atención!',
          text: 'Completar los datos del formulario',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      } else {
        const params = {
          url: 'meters079/install',
          method: 'PUT',
          customMessages: true,
          params: {
            uuid: this.meterUuid,
            userInstalerUuid: userData.uuid,
            serie: this.numberSpore,
            meterNumber: this.meterNumber,
            agencyUuid: this.agencies.uuid,
            zoneUuid: this.zone.value,
            division: this.division[0].value,
            typeTecnology: this.meterType.value,
            street: this.street,
            numExt: this.numExt,
            suburb: this.suburb,
            postalCode: this.postalCode,
            location: this.location,
            municipality: this.municipality,
            state: this.state,
            latlong: this.latlong
          }
        }

        await request(params).then(data => {
          this.$router.push('/meters/GWH0079')
        }).catch(e => {
          if (e.response.status === 409) {
            this.$swal({
              title: '¡Atención!',
              text: 'medidor ya existente, favor de revisar número',
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          }
        })
      }
    },
    async getMeterType () {
      this.opcMeterType = []
      const params = {
        url: '/meters079/tecnologies/all',
        method: 'GET'
      }
      await request(params).then(data => {
        for (let index = 0; index < data.data.data.length; index++) {
          this.opcMeterType.push({
            label: data.data.data[index].name,
            title: data.data.data[index].name,
            value: data.data.data[index].enum
          })
        }
      })
    },
    verifyUserUuid () {
      if (!userData.uuid) logout()
    }
  },
  watch: {
    division: function (val) {
      if (val.length) this.getCatZones()
    },
    zone: function (val) {
      if (val?.value) this.getCatAgencies()
    }
  },
  created () {
    this.verifyUserUuid()
    this.getCatDivision()
    this.getMeterType()
    this.getMetersNotInstalled()
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
